.site-footer {
  position: relative;
  color: $white;
  background: $blue;
  z-index: 0;
  a,
  a:link {
    color: $white;
  }
}

.site-footer__navigation-row {
  display: flex;
  margin-left: 0;
  margin-right: 0;
}

.nav-secondary-footer {
  display: inline-block;
  flex: 1 1 auto;
  margin-right: 2rem!important;
  @extend .ul-reset;

  .nav-secondary-footer__item {
    padding-right: 1rem;
    padding-bottom: 0.3rem;
    display: inline-block;

    &:last-child {
      padding-right: 0;
    }
  }
}

.social-footer {
  display: flex;
  flex: 0 0 auto;
  justify-content: end;
  text-align: right;
  margin-left: auto!important;
  @extend .ul-reset;

  .social-footer__item {
    padding-right: 1rem;

    &:last-child {
      padding-right: 0;
    }
  }

  .social-footer__heading {
    font-family: $body-font-stack;
    font-size: inherit;
    display: inline-block;
    margin-bottom: 0;
  }

  .social-footer__link {
    @extend .link-no-box-shadow;
    padding-right: 0;

    &::before {
      position: static;
      right: auto;
      top: auto;
      transform: none;
      font-size: 1.1rem;
    }
  }
}

.site-map-footer {
  @extend .clearfix;
  @extend .ul-reset;

  .site-map-footer__item {
    float: left;
    width: 33.33%;
    padding-right: 1rem;
    margin: 0;

    &:last-child {
      text-align: center;
      padding-left: 1.5rem;
      padding-right: 0
    }
  }

  .site-map-footer__lists {
    @extend .ul-reset;

    .site-map-footer__lists-item{
      padding-bottom: 0.5rem;

      &:last-child {
        padding-bottom: 0;
      }
    } 
  }

  .site-map-footer__category-heading {
    font-size: calc(1.4rem + (22.4 - 16) * ((100vw - 300px) / (1920 - 300)));
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  .site-map-footer__hr {
    display: none;
  }

  .site-map-footer__org-link {
    @extend .link-no-box-shadow;
    padding-right: 0;

    &::before {
      content: none;
      position: static;
      right: auto;
    }
  }
}

.site-footer__supporters {
  text-align: center;
  background: $white;

  .site-footer__supporters-title{
    font-family: $body-font-stack;
    color: $black;
    display: inline-block;
    margin-bottom: 0;
    @extend .font-size;
  }

  .site-footer__supporters-link {
    @extend .link-no-box-shadow;
    padding-right: 0;

    &::before {
      content: none;
      position: static;
      right: auto;
    }
  }
}

.site-footer__organization {
  font-size: .875em;
  
  small {
    font-size: inherit;
  }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@include media-breakpoint-down(xxl) {}

@media (max-width: 960px) {
  .site-map-footer {
    .site-map-footer__item {
      width: 50%;
      margin-bottom: 1.5rem;

      &:nth-child(2) {
        padding-left: 1rem;
        padding-right: 0;
      }

      &:last-child {
        float: none;
        width: 100%;
        clear: both;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
      }
    }

    .site-map-footer__hr {
      display: inherit;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 862px) {
  .social-footer__text{
    display: block;
    margin-right: 0;
  }

  .site-footer__organization {
    text-align: center;
  }
}

@media (max-width: 690px) {
  .site-footer__navigation-row {
    display: block;

    .nav-secondary-footer,
    .social-footer {
      width: 100%;
      justify-content: start;
      text-align: left;
      display: block;
    }

    .nav-secondary-footer {
      margin-bottom: 1rem;
    }

    .social-footer {
      .social-footer__item {
        display: inline-block;
      }
    }
  }
  
  .site-map-footer {
    .site-map-footer__hr {
      display: inherit;
    }

    .site-map-footer__item { 
      &:last-child {
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 615px) {
  .nav-secondary-footer {
    width: 100%;
    text-align: left;
  }

  .social-footer {
    width: 100%;
    text-align: left;
    margin-top: 1.5rem;

    .social-footer__text{
      padding-left: 0.5rem;
    }
  }

  .site-map-footer {
    display: block;

    > li {
      margin-bottom: 1.5rem;
    }

    > li:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 420px) {
  .site-footer__supporters {
    .site-footer__supporters-title {
      display: block;
    }
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@include media-breakpoint-down(md) {}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@include media-breakpoint-down(sm) {}
