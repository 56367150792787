html.noScroll,
body.noScroll {
	margin: 0;
	overflow: hidden;
}

.ie11-text {
	display: none;
}

.modalAnswer {
	text-align: center;
	margin-bottom: 2rem;

	&::before {
		content: "";
		font-size: 5rem;
		color: $white;
		width: 120px;
		height: 120px;
		margin: 1.5rem auto;
		display: block;
		text-align: center;
		line-height: 120px;
		border-radius: 50%;
		@extend .bootstrap-icon;
	}

	&.modalAnswerYes:before {
		content: "\F633";
		background: $green;
	}

	&.modalAnswerNo:before {
		content: "\F659";
		background: $red;
	}
}

.btnModalClose {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1.5rem;
	color: grey;
	border: none;
	padding: 1rem;
	background: none;
}

.customModal {
	opacity:0;
	visibility: hidden;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 1rem;
	z-index: 99999;
	overflow: hidden;
	transition: opacity 200ms ease 0s;
	background: rgba(0,0,0,0.8);

	&.modalShow {
		opacity: 1;
		visibility: visible;

		.modalInnerCont {
			top: 50%;
			opacity: 1;
		}
	}
}

.modalInnerCont {
	position: absolute;
	top: 60%;
	left: 50%;
	opacity: 0;
	transform: translate(-50%,-50%);
	width: 500px;
	margin: 0;
	padding: 4.6rem 1rem 1.5rem;
	transition: all 300ms ease 0s;
	background: rgb(255,255,255);
}

.btnContChoices {
	display: flex;
	justify-content: center;
	margin-top: 3rem;

	.btn {
		width: 250px;
		display: block;
		margin: 0 0.1rem;
	}
}

.img-hide {
	opacity: 0;
	visibility: hidden;
}
 
.deck {
	position: relative;
	z-index: -1;
	background: $light-grey;

	.card {
		flex: 1;
		padding: 1px;
		cursor: pointer;
		transition: transform 0.3s ease, box-shadow 0.3s ease;

		&:hover {
			transform: scale(1.05);
			box-shadow: 0 0 20px rgba($black,0.1);
		}
	}
}
.cardCont {
	position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: .3s ease-in-out;
}
.cardFront,
.cardBack {
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
	height: inherit;
	backface-visibility: hidden;
}
.cardFront {
	transform: rotateX(0);
	z-index: 1;
}
.cardBack {
	transform: rotateY(-180deg);
}
.deck .card.match .cardCont,
.deck .card.open .cardCont {
	cursor: default;
	transform: rotateY(180deg);
}
.deck .card.open,
.deck .card.match,
.deck .cardCont {
	position: relative;
	z-index: -1;
}

.deck .card.match .cardCont {
	cursor: default;
}

.photoCont {
	max-width: 305px;
	margin: 0 auto 1.5rem;
	display: flex;
	align-content: center;
	justify-content: space-around;

	.cardBack {
		position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		width: 150px;
		height: 210px;
		transform: none;
	}
}

.modalQuestionHeader {
	font-size: 1.5rem;
	text-align: center;
	text-transform: none;
	margin-bottom: 0;
}

.btnCont {
	text-align: center;
	padding: 1.5rem 1rem;
	margin: 2.5rem -1rem -1.5rem;
	justify-content: center;
	background: $blue;

	.btnOptions {
		margin: 0 0.1rem;
	}
}

@keyframes wobbleAnimation {
	0% { transform: translateX(0px); }
	15% { transform: translateX(-10px) rotate(-5deg); }
	30% { transform: translateX(10px) rotate(5deg); }
	45% { transform: translateX(-10px) rotate(-5deg); }
	60% { transform: translateX(10px) rotate(5deg); }
	75% { transform: translateX(-10px) rotate(-5deg); }
	90% { transform: translateX(10px) rotate(5deg); }
	100% { transform: translateX(0px); }
}
  
.wobble {
	animation-name: wobbleAnimation;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

@keyframes rubberbandAnimation {
	0% { transform: scaleX(1); }
	30% { transform: scaleX(1.25); }
	40% { transform: scaleX(0.75); }
	50% { transform: scaleX(1.15); }
	65% { transform: scaleX(0.95); }
	75% { transform: scaleX(1.05); }
	100% { transform: scaleX(1); }
}

.rubberBand {
	animation-name: rubberbandAnimation;
	animation-duration: 1s;
	animation-iteration-count: 1;
	transform-origin: center;
}





/*EN Cards*/
.cardFront {
	background: url(#{$image-path}/activities-games/billy-memory-game/cardback_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy1"],
.card[data-card-name="billy1"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy1_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy2"],
.card[data-card-name="billy2"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy2_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy3"], 
.card[data-card-name="billy3"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy3_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy4"],
.card[data-card-name="billy4"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy4_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy5"],
.card[data-card-name="billy5"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy5_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy6"],
.card[data-card-name="billy6"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy6_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy7"],
.card[data-card-name="billy7"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy7_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy8"],
.card[data-card-name="billy8"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy8_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="billy9"],
.card[data-card-name="billy9"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy9_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy1_friend"],
.card[data-card-name="buddy1_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy1_friend_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy2_friend"],
.card[data-card-name="buddy2_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy2_familypet_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy3_brother"],
.card[data-card-name="buddy3_brother"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy3_olderbrother_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy4_mom"],
.card[data-card-name="buddy4_mom"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy4_mom_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy5_grandpa"],
.card[data-card-name="buddy5_grandpa"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy5_grandpa_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy6_grandma"],
.card[data-card-name="buddy6_grandma"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy6_grandma_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy7_friend"],
.card[data-card-name="buddy7_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy7_friend_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy8_friend"],
.card[data-card-name="buddy8_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy8_friend_en.jpg) center center no-repeat;
	background-size: 100%;
}
.cardBack[data-card-name="buddy9_friend"],
.card[data-card-name="buddy9_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy9_friends_mom_or_dad_en.jpg) center center no-repeat;
	background-size: 100%;
}

/*FR*/
html[lang="fr"] .cardFront {
	background: url(#{$image-path}/activities-games/billy-memory-game/cardback_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy1"],
html[lang="fr"] .card[data-card-name="billy1"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy1_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy2"],
html[lang="fr"] .card[data-card-name="billy2"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy2_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy3"],
html[lang="fr"] .card[data-card-name="billy3"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy3_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy4"],
html[lang="fr"] .card[data-card-name="billy4"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy4_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy5"],
html[lang="fr"] .card[data-card-name="billy5"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy5_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy6"],
html[lang="fr"] .card[data-card-name="billy6"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy6_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy7"],
html[lang="fr"] .card[data-card-name="billy7"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy7_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy8"],
html[lang="fr"] .card[data-card-name="billy8"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy8_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="billy9"],
html[lang="fr"] .card[data-card-name="billy9"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/billy9_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy1_friend"],
html[lang="fr"] .card[data-card-name="buddy1_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy1_ami_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy2_friend"],
html[lang="fr"] .card[data-card-name="buddy2_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy2_animal_de_compagnie_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy3_brother"],
html[lang="fr"] .card[data-card-name="buddy3_brother"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy3_grand_frere_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy4_mom"],
html[lang="fr"] .card[data-card-name="buddy4_mom"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy4_maman_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy5_grandpa"],
html[lang="fr"] .card[data-card-name="buddy5_grandpa"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy5_grand_papa_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy6_grandma"],
html[lang="fr"] .card[data-card-name="buddy6_grandma"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy6_grand_maman_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy7_friend"],
html[lang="fr"] .card[data-card-name="buddy7_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy7_amie_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy8_friend"],
html[lang="fr"] .card[data-card-name="buddy8_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy8_amie_fr.jpg) center center no-repeat;
	background-size: 100%;
}
html[lang="fr"] .cardBack[data-card-name="buddy9_friend"],
html[lang="fr"] .card[data-card-name="buddy9_friend"] .cardBack{
	background: url(#{$image-path}/activities-games/billy-memory-game/buddy9_parents_de_mon_amie_fr.jpg) center center no-repeat;
	background-size: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.ie11-text {
		display: block;
	}
	.btnRestart {
		&.iecp-u32 {
			display: none;
		}
	}
}

@media (max-width: 576px) {
	.deck {
		.d-flex:last-of-type {
			.card {
				flex: 0 0 25%;
			}	
		}
	}

	.modalInnerCont {
		width: 100%;
	}
}


@media (max-width: 425px) {
	.modalAnswer {
		margin-bottom: 1rem;
	
		&::before {
			font-size: 4rem;
			width: 100px;
			height: 100px;
			line-height: 100px;
		}
	}

	.btnContChoices {
		display: block;
		margin-top: 2rem;

		.btn {
			width: 100%;
			margin: 0.2rem;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
