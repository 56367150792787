.bootstrap-icon {
    font-family: "bootstrap-icons";
    font-size: 0.7rem;
    font-weight: 100;
    display: inline-block;
}

.font-size {
    font-size: 100%;
    font-size: calc(1rem + (17.6 - 16) * ((100vw - 300px) / (1920 - 300)));
}

.position-all-0 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ul-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}

.link-no-box-shadow {
    &:link {
        box-shadow: none;
    }
    &:visited {
        box-shadow: none;
    }
    &:hover {
        box-shadow: none;
    }
    &:active {
        box-shadow: none;
    }
    &:focus {
        box-shadow: none;
    }
}

.clearfix {
    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }
