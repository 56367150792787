h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-stack-1;
  margin-bottom: 1.5rem;
}

h1, .h1 {
  font-size: 2.2rem;
  font-size: calc(2.2rem + (35.2 - 16) * ((100vw - 300px) / (1920 - 300)));
  line-height: $heading-line-height;
}
h2, .h2 {
  font-size: 1.8rem;
  font-size: calc(1.8rem + (28.8 - 16) * ((100vw - 300px) / (1920 - 300)));
  line-height: $heading-line-height;
}
h3, .h3 {
  font-size: 1.2rem;
  font-size: calc(1.2rem + (20.8 - 16) * ((100vw - 300px) / (1920 - 300)));
  line-height: $heading-line-height;
}
h4, .h4 {
  font-size: 1.15rem;
  line-height: $heading-line-height;
}
h5, .h5 {
  font-size: 1.12rem;
  line-height: $heading-line-height;
}
h6, .h6 {
  font-size: 1rem;
  line-height: $heading-line-height;
}
