@media print {
  
  // Good to err on the side of caution and greyscalify colour variables here.
  
  // CSS Grid and Flexbox declarations tend to really mess up print formatting,
  // so it's best to override those with block/inline-block/inline display.
  
  @page {
    margin: 1.75cm;
  }
  
  html {
    font-size: 85%;
  }
  body {
    display: block;
    overflow: visible;
  }
  
  img {
    max-width: 400px !important;
    filter: grayscale(1);
  }
  
  a {
    color: black !important;
    font-weight: bold !important;
    text-decoration: underline;
    text-decoration-color: #bbb;
    box-shadow: none !important;
    background-image: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  // Display links:
  a::after {
    position: relative !important;
    width: auto;
    content: " (https://billybuddy.ca" attr(href) ") ";
  }
  [lang="fr"] a::after {
    width: auto;
    content: " (https://martinnesortpasseul.ca" attr(href) ") ";
  }
  a[href^="http://"]::after,
  a[href^="https://"]::after,
  a[target="_blank"]::after {
    display: inline !important;
    padding: 0 !important;
    height: auto !important;
    background-color: transparent !important;
    width: auto !important;
    content: " (" attr(href) ") " !important;
    background-image: none !important;
  }
  // But not these:
  a[href^="#"]::after,
  a[href^="tel:"]::after,
  a[href^="mailto:"]::after,
  a[href^="javascript:"]::after {
    content: none !important;
  }
  
  abbr {
    text-decoration: none !important;
    border-bottom: none !important;
  }
  
  
}
