.carousel-indicators {
    [data-bs-target] {
        border: 1px solid transparent;
        background: rgba($black,0.5);
    }

    .active {
        background: $orange;
    }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@include media-breakpoint-down(sm) {
    .carousel-indicators {
        margin-bottom: 0;
    }
}
