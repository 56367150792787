.container {
  position: relative;
  max-width: 1024px;
  padding: 2.5rem 1.5rem;
  z-index: 1;
}


.site-header {
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.site-footer__navigation {
  .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.site-footer__supporters,
.site-footer__organization {
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

// For Mobile Navigation Modal
.mobile-nav-modal-active  {
  main {
    position: relative;
    z-index: 0;
  }
}

.desciption-image-container {
  display: flex;
  align-items: center;

  .desciption-image-container__inside-cont {
    flex: 2;
  }
  .desciption-image-container__img {
    flex: 1;
    padding-left: 2rem;
  }
}


// `xxl` applies to x-large devices (large desktops, less than 1400px)
@include media-breakpoint-down(xxl) {}

// `md` applies to small devices (landscape phones, less than 768px)
@include media-breakpoint-down(md) {
  .desciption-image-container {
    display: block;

    .desciption-image-container__inside-cont,
    .desciption-image-container__img {
      flex: none;
    }
    .desciption-image-container__img {
      padding-left: 0;
      padding-top: 2rem;

      img {
        max-width: 60%;
        display: block;
        margin: auto;
      }
    }
  }
}

@media (max-width: 400px) {
  .site-header {
    .container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
