.bs-icon-facebook::before {
    content: "\F344";
    @extend .bootstrap-icon;
}
.bs-icon-twitter::before {
    content: "\F5EF";
    @extend .bootstrap-icon;
}
.bs-icon-youtube::before {
    content: "\F62B";
    @extend .bootstrap-icon;
}
.bs-icon-instagram::before {
    content: "\F437";
    @extend .bootstrap-icon;
}
.bs-icon-download::before {
    content: "\F30A";
    -webkit-text-stroke: 1px;
    @extend .bootstrap-icon;
}
.bs-icon-pdf::before {
    content: "\F63D";
    @extend .bootstrap-icon;
}
.bs-icon-add-to-cart::before {
    content: "\F175";
    @extend .bootstrap-icon;
}
.bs-icon-menu::before {
    content: "\F479";
    @extend .bootstrap-icon;
}
.bs-icon-close::before {
    content: "\F62A";
    @extend .bootstrap-icon;
}


// Pasted Directly in the Markup
.bi-arrow-clockwise {
    -webkit-text-stroke: 1px;
}
