.c-img-banner {
  position: relative;
  display: flex;
  overflow: hidden;
}

.c-img-banner__title {
  margin-bottom: 1rem;
}

.c-img-banner__desc {
  max-width: 25rem;

  &.c-img-banner__right {
    margin-right: 0;
    margin-left: auto;
  }
}

.c-img-banner__image {
  height: auto;
  
  &.c-img-banner__image-small {
    max-width: 150px;
  }

  &.c-img-banner__image-large {
    max-width: 360px;
  }

  &.c-img-banner__image-right {
    float: right;
    margin-left: 1.5rem;
  }
  &.c-img-banner__image-left {
    float: left;
    margin-right: 1.5rem
  }
}

.c-img-banner__img {
  @extend .position-all-0;
  z-index: -1;
  background-size: cover;

  &.c-img-banner__fade-left::before {
    content: "";
    @extend .position-all-0;
    display: block;
    background-image: linear-gradient(to right,rgba(255,255,255,1) 30%,rgba(255,255,255,0) 60%);
  }

  &.c-img-banner__img--kids-game {
    background-image: url(#{$image-path}/home/bg-kids-game.jpg);
    background-position: right center;
    background-size: 77%;
    background-repeat: no-repeat;
  }
}

@media (max-width: 985px){
  .c-img-banner__img {
    &.c-img-banner__img--kids-game {
      background-size: 98%;
    }
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@include media-breakpoint-down(md) {
  .c-img-banner__image {
    &.c-img-banner__image-small {
      max-width: 110px;
    }
  }

  .c-img-banner {
    display: block;
 
    &.c-img-banner--kids-game {
      img {
        float: none;
        position: absolute;
        top: -171px;
        left: 0;
      }
    }

    .c-img-banner__img {
      &.c-img-banner__img--kids-game {
        background-size: cover;
      }
    }
  }

  .c-img-banner__img {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 100%;
    min-height: 14rem;
    z-index: 1;

    &.c-img-banner__fade-left::before {
      content: none;
    }
  }

  .c-img-banner__desc {
    max-width: none;
  }
}
