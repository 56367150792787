$image-path: '/static/images/';
$font-path: '/static/fonts/';


:root {
  --c3p-blue-light: #748db9;
  --c3p-blue-dark: #004b8c;
  --c3p-green: #3c9339;
}

$font-family-sans-serif: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif';
$body-font-stack: 'OpenSans', $font-family-sans-serif;
$header-font-stack-1: 'Oswald', $font-family-sans-serif;
$line-height-base: 1.5;
$heading-line-height: 1.4;

$grey: rgb(204,204,204);
$light-grey: rgb(242,242,242);
$black: rgb(0,0,0);
$light-black: rgb(33,37,41);
$white: rgb(255,255,255);
$blue: rgb(0,106,156);
$dark-blue: rgb(0,68,115);
$light-blue: rgb(18,139,196);
$orange: rgb(203,124,31);
$light-orange: rgb(244,125,41);
$green: rgb(55,178,75);
$red: rgb(255,0,0);

// Color for the Home banner
$blue-home-banner: rgb(0,179,240);
$orange-home-banner: rgb(249,157,28);
