html {
    min-width: 320px;
    @extend .font-size;
}

body {
    position: relative;
    font-family: $body-font-stack;
    font-style: normal;
    font-weight: normal;
    line-height: $line-height-base;
}

p {
    margin-bottom: 1.5rem;
}

.text-blue {
    color: $blue;
}

.bg-image-about-us {
    padding-bottom: 72%;
    background: url(#{$image-path}/about/about-us-building.jpg) no-repeat center bottom;
    background-size: contain;
}

.list--spaced > li{
    margin-bottom: 0.5rem;
}
