.banner {
  display: flex;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  background: $grey;

  &.home {
    .banner-item {
      padding-top: 30%;
      background-size: 100% auto!important;
      transition: background-size 0.3s ease;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: block;
        background: rgba($blue-home-banner, 0.6);
      }

      &:hover {
        background-size: 120% auto!important;
      }

      .banner-item__link {
        transition: background 300ms ease;
      }
      .banner-item__link:hover {
        background: $blue-home-banner;
      }
    }

    .banner-item:last-of-type {
      &:before {
        background: rgba($orange-home-banner, 0.6);
      }

      .banner-item__link:hover {
        background: $orange-home-banner;
      }
    }
  }

  .banner-item {
    position: relative;
    width: 100%;
    padding-top: 25%;
    background-position: center center!important;
    background-size: cover!important;

    &.banner-item__no-banner-image {
      padding-top: 0;
      border-bottom: 20px solid $blue;
      background: none;
    }

    .banner-item__link,
    .banner-item__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: auto;
      color: $white;
      text-decoration: none;
      padding: 0;
      z-index: 1;
    }
    .banner-item__border {
      padding: 0.5rem 1rem;
      border: 2px solid $white;
    }

    .banner-item__link {
      @extend .link-no-box-shadow;
    }
  

    // Home
    &.banner-item__home-parents {
      background: url(#{$image-path}/home/banner_parents.jpg);
    }
    &.banner-item__home-teachers {
      background: url(#{$image-path}/home/banner_teachers.jpg);
    }

    // About
    &.banner-item__about {
      background: url(#{$image-path}/about/banner_about.jpg);
    }

    // Teachers
    &.banner-item__teachers {
      background: url(#{$image-path}/teachers/banner_teachers.jpg);
    }

    // __Materials
    &.banner-item__materials {
      background: url(#{$image-path}/materials/banner_materials.jpg);
    }

    // __Teacher Kit
    &.banner-item__teacher-kit {
      background: url(#{$image-path}/teacher-kit/banner_teacher-kit.jpg);
    }

    // __Billy Activity Book
    &.banner-item__billy-activity-book {
      background: url(#{$image-path}/billy-activity-book/banner_billy-activity-book.jpg);
    }

    // Parents
    &.banner-item__parents {
      background: url(#{$image-path}/parents/banner_parents.jpg);
      background-position-y: 25%!important;
    }

    // __The Buddy System
    &.banner-item__the-buddy-system {
      background: url(#{$image-path}/the-buddy-system/banner_the-buddy-system.jpg);
    }

    // Activities & Games
    &.banner-item__activities-games {
      background: url(#{$image-path}/activities-games/banner_pactivities-games.jpg);
    }
  }
}

// Applies to medium devices (tablets, less than 992px)
@include media-breakpoint-down(lg) { 
  .banner {
    display: block;

    &.home {
      height: auto;

      .banner-item {
        padding-top: 45%;
      }
    }

    .banner-item {
      width: 100%!important;
      display: block;
      padding-top: 35%;
    }
  }
}
