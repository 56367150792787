.c-link-box__img {
  padding-top: 69%;
  margin-bottom: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-link-box__link {
  width: 100%;
  height: 100%;
  display: block;
  padding: 1rem;
  border: 1px solid $white;
  background: $light-grey;
  transform: translateY(0);
  transition: background-color 300ms ease, border-color 300ms ease, box-shadow 300ms ease, transform 300ms ease!important;
  @extend .link-no-box-shadow;

  &:hover,
  &:active,
  &:focus {
    border-color: $light-grey;
    box-shadow: 0 0.1rem 0.2rem rgba(0,0,0,.2);
    transform: translateY(-0.2rem);
    background: $white;
  }
}

.c-link-box__heading {
  color: $black;
  margin-bottom: 0;
}

.c-link-box__desc {
  margin-bottom: 0;
}

.c-link-box__link-info {
  font-size: 1rem;
  font-style: italic;
  color: $black;
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  .c-link-box {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding: 0.5rem;
  }
}

@include media-breakpoint-up(sm) {
  .c-link-box {
    width: 33%;
  }
}

@supports (display: grid) {
  .c-link-boxes {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
  }

  .c-link-box {
    width: auto;
  }

  @media screen and (min-width: 30em) {
    .c-link-boxes{
      grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    }
  }
}
