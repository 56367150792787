a,
a:link {
    position: relative;
    color: $blue;
    text-decoration: none;
    text-decoration-skip: auto;
    box-shadow: inset 0 -2px 0 0 $blue;
    transition: color 300ms, box-shadow 300ms;
}
a:visited {
    color: $dark-blue;
    box-shadow: inset 0 -2px 0 0 $dark-blue;
}
a:hover {
    color: $dark-blue;
    box-shadow: inset 0 -2px 0 0 $dark-blue;
}
a:active {
    color: $dark-blue;
    box-shadow: inset 0 -2px 0 0 $dark-blue;
}
a:focus {
    color: $dark-blue;
    box-shadow: inset 0 -2px 0 0 $dark-blue;
}

[target="_blank"] {
    &::after{
        content: "\F1C5";
        display: inline-block;
        margin-left: 0.4rem;
        @extend .bootstrap-icon;
    }
    &.no-external-link-icon  {
        &::after {
            content: none;
        }
    }
}

[href$=".pdf"] {
    &::after{
        content: "\F63D";
        display: inline-block;
        margin-left: 0.4rem;
        @extend .bootstrap-icon;
    }

    &.no-pdf-icon {
        &::after{
            content: normal;
            margin-left: 0;
        }
    }
}

[href^="tel"] {
    white-space: nowrap;
}

a[role="button"] {
    @extend .link-no-box-shadow;
}
