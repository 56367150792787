.navbar-brand {
    @extend .link-no-box-shadow;
}

.bs-icon-menu,
.bs-icon-close {
    &::before {
        font-size: inherit!important;
        margin-right: 0.3rem;
    }
}
.btn.btn-nav-menu  {
    display: none;
}


.btn-mobile-nav {
    z-index: 9999;
    border: none;
    background: rgba(18,139,196,1);

    &:hover {
        background: rgba(18,139,196,0.9)!important;
    }
    &:active {
        background: rgba(18,139,196,0.9)!important;
    }
}

.navbar {
    padding: 0;

    .navbar-main {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .nav-item {
        padding: 0 0.5rem;
    
        &:last-child {
            padding-right: 0;
        }
    }

    .nav-link {
        font-style: italic;
        font-weight: 600;
        color: $black;
        display: inline-block;
        box-shadow: none;
    
        &:hover {
            box-shadow: inset 0 -2px 0 0 $dark-blue;
        }
        
    }
}


// Hides The Modal on Mobile Nav
.mobile-nav-modal-active {
    .modal-backdrop {
        visibility: hidden;
    }
}

.mobile-nav-modal-container {
    .modal-dialog {
        width: 100%;
        max-width: 100%;
        margin: 0;
        transition: transform .1s ease-out!important;
    }

    .modal-content {
        border: none;
        border-radius: 0;
        background: $blue!important;
    }

    .modal-header,
    .modal-body {
        border:none!important;
        border-radius: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .modal-header {
        padding-top: 0.1rem;
        padding-bottom: 0;
    }

    .modal-body {
        padding-top: 0;
    }

    hr {
        opacity: 1;
        border-color: $dark-blue;
    }


    .navbar-main {
        display: block;
    }

    .nav-item {
        padding-left: 0;
        padding-right: 0;
    }

    .nav-link {
        font-family: $header-font-stack-1;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: normal;
        color: $white!important;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }

    .navbar-main__secondary {
        column-count: 2;
        column-gap: 1rem;

        .nav-item {
            break-inside: avoid;
            display: block;
        }
        .nav-link {
            // font-family: $header-font-stack-2;
            font-size: 1rem;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
        }
    }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@include media-breakpoint-down(xxl) {}

// Large devices (desktops, 992px and up)
@media (max-width: 955px){
    .navbar-container {
        display: none;
    }

    .btn.btn-nav-menu  {
        display: inherit;
    }
}

// `md` applies to small devices (landscape phones, less than 768px)
@include media-breakpoint-down(md) {}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@include media-breakpoint-down(sm) {}
