video {
  width: 100%;
  display: block;
}

.plyr__poster {
  background-size: cover;
}

.plyr__control--overlaid {
  background: $blue;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover, 
.plyr--video .plyr__control[aria-expanded=true] {
  background: $orange;
}
