@mixin btn-colours ($bg-colour, $bg-hover, $bg-active, $text-colour: #fff) {
  color: $text-colour!important;
  box-shadow: none!important;
  border: none;
  background-color: $bg-colour!important;
  transition: background-color 300ms ease!important;

  &:hover {
    color: $text-colour!important;
    background-color: $bg-hover!important;
  }
  &:active {
    color: $text-colour!important;
    background-color: $bg-active!important;
  }
  &:focus {
    color: $text-colour!important;
    background-color: $bg-hover!important;
  }
}

.btn {
  position: relative;
  font-size: 0.95rem;
  font-weight: 600;

  &.btnRestart {
    width: 200px;
    display: block;
    margin: 2.4rem 0 0 auto;
  }
}

.btn-blue {
  @include btn-colours ($blue, $orange, $orange);
}

.btn-dark-blue {
  @include btn-colours ($dark-blue, $orange, $orange);
}

.btn-nav-close {
  @include btn-colours ($light-blue, rgba($light-blue,0.9), rgba($light-blue,0.9));
}

.btn-nav-menu {
  @include btn-colours ($light-orange, rgba($light-orange,0.9), rgba($light-orange,0.9));
}


// `sm` applies to x-small devices (portrait phones, less than 576px)
@include media-breakpoint-down(sm) {
  .btn {
    &.btnRestart {
      margin-right: auto;
    }
  }
}
