.c-download-buttons {
    display: flex;
    align-items: center;
}

.c-download-buttons__no-flex {
    display: block;
    width: auto;
    max-width: 600px;

    .c-download-button__container {
        padding: 0 0 0 1.5rem;
    }
}

.c-download-button {
    align-items: center;
}

.c-download-button__heading {
    color: $blue;
}
.c-download-button__media-container {
    width: 35%;
    border: 1px solid $grey;
    padding: 0.5rem;
    text-align: center;
}
.c-download-button__container {
    width: 65%;
    padding: 0 3rem 0 1.5rem;

    .btn {
        font-size: 0.85rem;
        display: inline-block;
        padding-left: 1rem;
        padding-right: 1rem;

        &::before {
            content: normal;
        }
        &::after {
            content: "\F30A";
            margin-left: 0.3rem;
            -webkit-text-stroke: 1px;
            @extend .bootstrap-icon;
        }
    }
}


.c-download-buttons__section {
    position: relative;
}

.c-download-buttons__banner-image {
    @extend .position-all-0;
    z-index: 0;

    &.c-download-buttons__fade-left::before {
        content: "";
        @extend .position-all-0;
        display: block;
        background-image: linear-gradient(to right,rgba(255,255,255,1) 45%,rgba(255,255,255,0) 65%);
    }
    
    &.c-download-buttons--bg-image-teachers {
        background: url(#{$image-path}/teachers/teacher-and-student.jpg) no-repeat 105% top;
        background-size: 65%;
    }
}

@media (max-width: 1530px) { 
    .c-download-buttons__banner-image {
        &.c-download-buttons--bg-image-teachers {
            background-size: 95%;
            background-position-x: 480%;
        }
    }
}

// Applies to medium devices (tablets, less than 992px)
@include media-breakpoint-down(lg) { 
    .c-download-buttons {
        display: block;
    }

    .c-download-button {
        width: 100%;
        margin-bottom: 1.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .c-download-button__media-container {
        width: 20%;
        min-width: 165px;
    }
    .c-download-button__container {
        width: 80%;
        padding-right: 0;
    }
}

// `md` applies to small devices (landscape phones, less than 768px)
@include media-breakpoint-down(md) { 
    .c-download-buttons__banner-image {
        padding-top: 50%;
        position: static;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
    
        &.c-download-buttons__fade-left::before {
            content: normal;
        }
        &.c-download-buttons--bg-image-teachers {
            background-size: cover;
            background-position-x: left;
        }
    }

    .c-download-buttons {
        &.c-download-buttons__no-flex {
            max-width: 100%;
        }
    }
}

@media (max-width: 420px) { 
    .c-download-button {
        display: block!important;
    }

    .c-download-button__media-container {
        margin: 0 auto 1rem;
    }

    .c-download-button__container {
        width: auto;
        padding: 0;
        margin: auto;
        text-align: center;
    }

    .c-download-buttons__no-flex {
        .c-download-button__container {
            padding: 0;
        }
    }
}
